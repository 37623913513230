/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LogInComponent } from './log-in/log-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { DemoSignUpComponent } from './sign-up/demo-sign-up/demo-sign-up.component';
import { UserAuthModule } from 'app/user-auth/module';
import { JsccSignUpComponent } from './sign-up/jscc-sign-up/jscc-sign-up.component';
import { environment } from 'environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    DemoSignUpComponent,
    JsccSignUpComponent,
    LogInComponent,
    SignUpComponent
  ],
  imports: [
    UserAuthModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: '',
        component: LogInComponent,
        data: {
          title: 'Log in to OpenGamma'
        }
      },
      {
        path: 'jscc',
        component: LogInComponent,
        data: {
          organization: environment.jsccTenantId,
          loginMessage: 'Log in to JSCC ETD VaR Margin Simulations',
          title: 'Log in to JSCC ETD VaR Margin Simulations'
        }
      },
      {
        path: 'demo-sign-up',
        component: DemoSignUpComponent,
        data: {
          title: 'Sign Up for OpenGamma'
        }
      },
      {
        path: 'jscc-sign-up',
        component: JsccSignUpComponent,
        data: {
          title: 'Sign Up for OpenGamma'
        }
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
