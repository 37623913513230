<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="logo-container">
  <img class="logo" src="assets/open-logo-white.svg">
</div>

<div *ngIf="message" class="message">
  {{message}} <a class="text__link" href="mailto:{{contactEmail}}">{{contactEmail}}</a>
</div>

<div *ngIf="showSuccessPendingMessage" class="message message--compact message--justified">
  <p>
    Sorry, your email doesn't appear to be on our pre-approved list.
  </p>
  <p>
    Kindly email a request to <span style="white-space: nowrap">jscc-sign-up@opengamma.com</span> from your email
    address with Subject: "Access approval to JSCC ETD VaR margin simulation tool".
  </p>
  <p>
    Note: When you send the above request, your action confirms:
  </p>
  <ol>
    <li>You intend to only use the tool for calculating the JSCC VaR margin requirements.</li>
    <li>You are not a competitor of OpenGamma and JSCC.</li>
  </ol>
  <p>
    We will email you once your request has been approved.
  </p>
  <hr/>
  <p>
    あなたのe-mail アドレスは、事前に承認されたリストに登録されていないようです。
  </p>
  <p>
    お手数をおかけしますが、あなたのe-mailアドレスから、<span style="white-space: nowrap">jscc-sign-up@opengamma.com</span> 宛に依頼のメールを送ってください。メールの件名は、"Access approval to JSCC
    ETD VaR margin simulation tool" としてください。
  </p>
  <p>
    留意事項：あなたが上記の依頼メールを送ると、あなたは以下の事項を確認したことになります。
  </p>
  <ol>
    <li>あなたは、JSCCのVaR証拠金の試算のためにのみ、このツールを使うこと。</li>
    <li>あなたは、OpenGamma及びJSCCと競合する者ではないこと。</li>
  </ol>
  <p>
    あなたの依頼が承認され次第、以下のメールをそれぞれのメールアドレスからをお送りします。
  </p>
  <ul>
    <li>
      Create a password for OpenGamma： <span style="white-space: nowrap">production@opengamma.com</span><br/>
      →メール文中のCreate a password に埋め込まれたリンクから、パスワードを登録してください。
    </li>
    <li>
      登録承認のご連絡： <span style="white-space: nowrap">jscc-sign-up@opengamma.com</span><br/>
      →プラットフォームへのログイン方法が記載してあります。
    </li>
  </ul>
</div>

<div class="container" *ngIf="!message && !showSuccessPendingMessage">
  <div class="text-panel__container">
    <div class="text-panel__calendar" *ngIf="selectedEnvironment.isDemo">
      <div class="calendar__digit">
        7
      </div>
    </div>
    <div class="text-panel__text">
      {{ _environmentConfig.panelText || 'Sign up to see OpenGamma Analytics in action' }}
      <div class="text-panel__subtext" *ngIf="selectedEnvironment.isDemo">
        Your 7-day demo lasts until {{getTodayPlusOneWeek()}}
      </div>
    </div>
  </div>
  <form
    (submit)="submit(signUpForm)"
    id="form"
    #signUpForm="ngForm"
  >
    <div class="inputs__container">
      <div class="demo-type__container" *ngIf="_environmentConfig.signUpEnvironments.length > 1">
        <div class="demo-type" *ngFor="let env of _environmentConfig.signUpEnvironments"
          [class.demo-type--active]="selectedEnvironment.name === env.name"
          (click)="selectedEnvironment = env"
        >{{env.name}}</div>
      </div>
      <input
        class="form__input"
        type="text"
        placeholder="First name"
        id="firstname"
        name="firstname"
        required
        [(ngModel)]="firstName"
        #firstNameInput="ngModel"
        [class.error]="firstNameInput.touched && firstNameInput.invalid"
      >
      <div
        class="error-message"
        *ngIf="firstNameInput.touched && firstNameInput.invalid"
      >Please enter your first name</div>
      <input
        class="form__input"
        type="text"
        placeholder="Last name"
        id="lastname"
        name="lastname"
        required
        [(ngModel)]="lastName"
        #lastNameInput="ngModel"
        [class.error]="lastNameInput.touched && lastNameInput.invalid"
      >
      <div
        class="error-message"
        *ngIf="lastNameInput.touched && lastNameInput.invalid"
      >Please enter your last name</div>
      <input
        class="form__input"
        type="text"
        placeholder="Company"
        id="company"
        name="company"
        required
        [(ngModel)]="company"
        #companyInput="ngModel"
        [class.error]="companyInput.touched && companyInput.invalid"
      >
      <div
        class="error-message"
        *ngIf="companyInput.touched && companyInput.invalid"
      >Please enter the name of the company your work for</div>
      <input
        class="form__input"
        type="text"
        placeholder="Job title"
        id="position"
        name="position"
        required
        [(ngModel)]="jobTitle"
        #jobTitleInput="ngModel"
        [class.error]="jobTitleInput.touched && jobTitleInput.invalid"
      >
      <div
        class="error-message"
        *ngIf="jobTitleInput.touched && jobTitleInput.invalid"
      >Please enter your job title</div>
      <input
        class="form__input"
        type="email"
        placeholder="Work email address"
        id="email"
        name="email"
        required
        [(ngModel)]="email"
        email
        #emailInput="ngModel"
        spellcheck="false"
        [class.error]="emailInput.touched && emailInput.invalid"
      >
      <div class="error-message" *ngIf="emailInput.touched && emailInput.invalid">
        Please enter a valid email address
      </div>
      <div class="opt-in__container" *ngIf="selectedEnvironment.isDemo">
        <div class="checkbox__container">
          <div class="checkbox">
            <input
              required
              type="checkbox"
              value="None"
              id="consent"
              name="consent"
              [(ngModel)]="consent"
              checked
            />
            <label for="consent"></label>
          </div>
        </div>
        <label class="opt-in__label" for="consent">
          You should most definitely agree to the trial <a class="text__link" target="_blank" href="https://opengamma.com/trial-terms-and-conditions/">T&Cs</a>.
        </label>
      </div>
      <div class="opt-in__container" *ngIf="selectedEnvironment.jsccTermsConsentRequired">
        <div class="checkbox__container">
          <div class="checkbox">
            <input
              required
              type="checkbox"
              value="None"
              id="jsccTermsConsent"
              name="jsccTermsConsent"
              [(ngModel)]="jsccTermsConsent"
              checked
            />
            <label for="jsccTermsConsent"></label>
          </div>
        </div>
        <label class="opt-in__label" for="jsccTermsConsent">
          By checking the box you indicate that you have read and agreed to the
          OpenGamma
          <a class="text__link" target="_blank" [href]="jsccTermsConditionsLink"
            >Terms and Conditions</a>
          for the JSCC ETD VaR Margin Simulation Tool and to our
          <a
            class="text__link"
            target="_blank"
            href="https://opengamma.com/terms-conditions/#Privacy_Policy"
            >Privacy Policy</a
          >.
        </label>
      </div>
      <button
        class="content__button"
        type="submit"
        id="submit"
        *ngIf="!isSubmitting; else isFormSubmitted"
        [disabled]="!signUpForm.form.valid"
      >
        Sign up
      </button>
      <ng-template #isFormSubmitted>
        <button
          class="content__button"
          [disabled]="true"
        >
          <div class="og-spinner-container">
            <div class="og-spinner"></div>
          </div>
        </button>
      </ng-template>
      <div class="footer__label" *ngIf="_environmentConfig?.displayLoginLink">
        Already registered?
        <a class="text__link" target="_blank" href="/?tenantId={{selectedEnvironment?.tenantId}}">Login here</a>.
      </div>
    </div>
  </form>

</div>
